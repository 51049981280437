import React from 'react';
import PropTypes from 'prop-types';
import ManualPromoBar from './ManualPromoBar'
// import AutomaticPromoBar from './AutomaticPromoBar'

const PromoBarComponents = {
  manual: ManualPromoBar,
  // automatic: AutomaticPromoBar,
};

const PromoBar = ({ className = '', data, trackingData }) => {
  const { type } = data;
  const Component = PromoBarComponents[type];
  if (!Component) {
    return null
  }
  return <Component className={className} trackingData={trackingData} {...data} />
};

PromoBar.propTypes = {
  className: PropTypes.string,
  trackingData: PropTypes.object,
  data: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

export default PromoBar;
