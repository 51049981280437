import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { ABeagleContext } from '../../../contexts';

/**
 * When running an experiment switching content on latest tab,
 *  - tabs: always the main control data, pass directly from props
 *  - abTestZones: any populated ab test zones, pass directly from props
 *  - experiment: send in the name of the A/Beagle experiment.  If no experiment is running, send empty string
 *  - mapping: send an an object which connects the variant name to the zone name
 *    Always assume "control" is existing latest feed, and do not need to provide
 *    { variant_name_1: 'ab_test_1', ... }
 */
const useTabsWithLatestABTests = ({
  experiment = '',
  mapping = {},
  tabs,
  abTestZones
}) => {
  const { experiments, getExperimentValue } = useContext(ABeagleContext);
  const [renderedTabs, setRenderedTabs] = useState(!experiment ? tabs : null);
  useEffect(() => {
    // A/B experiments have loaded (client side) from upstream
    if (experiments?.loaded) {
      // fetch the variant (bucketing) for that experiment by name
      const variant = getExperimentValue(experiment, {
        rejectErrors: false,
      });
      const updatedTabs = tabs;
      if (variant && variant !== 'control') {
        // there is a non variant control; check if there is a match to zone provided
        const zoneName = mapping[variant]
        if (zoneName) {
          // there is a mapping; now check if that zone exists
          const updatedLatest = (abTestZones || {})?.[zoneName];
          if (updatedLatest) {
            const latest = updatedTabs.find((tab) => tab.name === 'tab_latest');
            if (latest) {
              latest.items = updatedLatest.items;
              latest.next = updatedLatest.next;
            }
          }
        }
      }
      setRenderedTabs(updatedTabs);
    }
  }, [experiments?.loaded]);

  return renderedTabs;
}

useTabsWithLatestABTests.propTypes = {
  experiment: PropTypes.string.isRequired,
  mapping: PropTypes.object.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      items: PropTypes.array,
      next: PropTypes.string,
    })
  ).isRequired,
  abTestZones: PropTypes.object.isRequired,
};

export default useTabsWithLatestABTests;
