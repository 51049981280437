import { useMediaQuery } from '../../../hooks';
import AdUnit from '../AdUnit';
import AdPlaceholder from './AdPlaceholder';

const AdSectionAwareness = ({ placeholderClassName = '', position = 'top' }) => {
  const { isMobile } = useMediaQuery();

  if (isMobile && position === 'top') {
    return (
      <AdUnit type="awareness" className="Ad--top-awareness" />
    );
  } else if (position === 'body') {
    return (
      <AdPlaceholder className={placeholderClassName}>
        <AdUnit
          className="Ad--section-awareness"
          stickyWithinPlaceholder={true}
          type={isMobile ? 'story1' : 'awareness'}
        />
      </AdPlaceholder>
    );
  } else {
    return null;
  }
};

export default AdSectionAwareness;
