import { useEffect, useState } from 'react';
import { useMediaQuery } from '../../../hooks';
import FeedTabs from '../../FeedTabs';
import useTabsWithLatestABTests from './useTabsWithLatestABTests';

const HomeFeedTabs = ({ tabs, abTestZones }) => {
  const { breakpoint } = useMediaQuery();
  const [ adsIndex, setAdsIndex ] = useState(2);

  const renderedTabs = useTabsWithLatestABTests({
    experiment: '',
    mapping: {},
    tabs,
    abTestZones
  });

  useEffect(() => {
    if (!breakpoint) return;

    if (breakpoint === 'xs' || breakpoint === 'sm') {
      setAdsIndex(2);
    } else {
      setAdsIndex(1);
    }

  }, [breakpoint]);

  // do not render until after experiments fetch to avoid accidental extra impressions
  if (!renderedTabs) {
    return null;
  }
  return (
    <FeedTabs tabs={renderedTabs} adsStartIndex={adsIndex} />
  );
};

export default HomeFeedTabs;
