/*eslint-disable camelcase*/
import { useEffect, useMemo } from 'react';
import { getConfig } from '../utils/config';
import { resolveAuthorization } from '../utils/auth';
import {
  filterZonesByPrefix,
  getFactOfTheDay,
  getSponsorshipStyles,
  normalizeSponsorship,
  normalizeZones,
} from '../utils/data';
import { isBfExternalLink } from '../utils/isBfExternalLink';
import HomepageSiteHeader from '../components/SiteHeader';
import Splash from '../components/Splash';
import PromoBar from '../components/PromoBar'; // move this component to different dir
import HomeFeedTabs from '../components/HomePage/HomeFeedTabs';

// apis
import { getSiteHeader } from '../upstreams/bf-header-ui';
import { getPageByName, getZoneById } from '../upstreams/feed-api';
import setResponseHeaders from '@buzzfeed/headers';
import { MAX_AGE_SHORT } from '../constants/feeds';
import { CLUSTER } from '../constants';
import { getFeedPageSurrogateKeys } from '../utils/surrogate-keys';

// other components
import CustomHead from './_head';
import HomepageWrapper from '../components/HomepageWrapper';
import SponsoredBar from '../components/SponsoredBar';
import AdmiralScript from '../components/AdmiralScript';

// ad components
import AdSectionAwareness from '../components/Ads/units/AdSectionAwareness';

// svgs
import { Fire } from '../icons/Fire';
import { FireSpeechBubble } from '../icons/FireSpeechBubble';
import { UpwardTrend } from '../icons/UpwardTrend';
import { Quiz } from '../icons/Quiz';
import { Sparkles } from '../icons/Sparkles';

const tabConfig = {
  tab_sponsored:{
    buttonLabel: (<><Sparkles/> <span>Sponsored</span></>),
    headline: ''
  },
  tab_latest: {
    buttonLabel: (<><Fire/> <span>Latest</span></>),
    headline: (<><Fire/> <span>Catch Up On The Latest</span></>)
  },
  tab_discussion: {
    buttonLabel: (<><FireSpeechBubble/> <span>Discussion</span></>),
    headline: (<><FireSpeechBubble/> <span>Discussion</span></>),
  },
  tab_trending: {
    buttonLabel: (<><UpwardTrend/> <span>Trending</span></>),
    headline: (<><UpwardTrend/> <span>What&apos;s Trending Now</span></>),
  },
  tab_quiz: {
    buttonLabel: (<><Quiz/> <span>Quiz</span></>),
    headline: (<><Quiz/> <span>Take Popular Quizzes</span></>),
  },
};

export function Page({
                       header,
                       pageConfig,
                       previewTimestamp,
                       sponsorship,
                       zones = {},
                     }) {
  const splashZones = useMemo(() => filterZonesByPrefix(zones, 'splash_'), [zones]);
  const tabZones = useMemo(() => filterZonesByPrefix(zones, 'tab_'), [zones]);
  const abTestZones = useMemo(() => filterZonesByPrefix(zones, 'ab_test'), [zones]);

  const inlineCss = useMemo(() => getSponsorshipStyles(sponsorship), [sponsorship]);

  const extraItems = useMemo(() => ({
    tab_trending: zones.trending_annex?.items || [],
  }), [zones]);

  const factOfTheDay = getFactOfTheDay(zones?.fact_of_the_day?.items, previewTimestamp);

  const promoBar = zones.promo?.items?.[0]?.content || null;

  const tabOrder = ['tab_latest', 'tab_sponsored', 'tab_trending', 'tab_quiz'];
  const orderMap = new Map();
  tabOrder.forEach((item, index) => orderMap.set(item, index));

  const tabs = useMemo(() => (
    Object.entries(tabZones)
      .map(([name, props], index) => {
        const tab = {
          ...props,
          headline: tabConfig[name]?.headline || '',
          label: tabConfig[name]?.buttonLabel || '',
          extraItems: extraItems[name] || [],
        }

        if (name === 'tab_sponsored') {
          tab.className = 'isSponsored';
          const sponsoredTabName = sponsorship?.data?.tab?.tab?.text || 'Sponsored';
          const sponsoredTabIcon = !!sponsorship?.data?.tab?.cta?.icon?.length ?
            <img src={sponsorship.data.tab.cta.icon} alt={sponsoredTabName} width={14} aria-hidden="true"/>
            : <Sparkles/>;
          tab.label = <>
            {sponsoredTabIcon}
            <span>{sponsoredTabName}</span>
          </>;
          tab.headline = <>{sponsoredTabIcon} <span>{sponsoredTabName}</span></>;
        }
        if (name === 'tab_trending') {
          tab.startIndexLabel = splashZones?.splash_trending?.items?.length || 0;
        }
        return tab;
      })
      /**
       * Omit sponsored if not enabled
       * @todo Ignore discussion for now
       */
      .filter(tab =>
        !!tab.items.length && // must always include a non empty items array
        (
          (tab.name === 'tab_sponsored' && sponsorship?.data?.tab?.enabled) ||
          (tab.name !== 'tab_sponsored' && tab.name !== 'tab_discussion')
        )
      ).sort((a, b) => {
      const indexA = orderMap.get(a.name);
      const indexB = orderMap.get(b.name);
      return indexA - indexB;
    })
  ), [tabZones, sponsorship, extraItems, splashZones]);

  const postFactOfTheDay = (eventMessage) => {
    if (eventMessage?.data?.type === 'topic-nav-loaded' && factOfTheDay?.text) {
      window.postMessage(
        {
          type: 'fact-of-the-day',
          message: { ...factOfTheDay, forceNewBrowserTab: isBfExternalLink(factOfTheDay?.url)}
        },
        '*'
      );
    }
  };

  useEffect(() => {
    window.addEventListener('message', postFactOfTheDay);

    return () => {
      window.removeEventListener('message', postFactOfTheDay);
    };
  }, []);

  return (
    <>
      <HomepageWrapper pageName={'home'}>
        <AdmiralScript />
        <HomepageSiteHeader {...header} />
        <CustomHead pageConfig={pageConfig} inlineCss={inlineCss} />
        <SponsoredBar data={sponsorship?.data?.tab} sponsor={sponsorship?.sponsor} tabs={tabs} />
        <Splash zones={splashZones} isTrackable={true} />
        {promoBar?.type === 'manual' && (
          <PromoBar
            data={promoBar}
            className="feed-content-area"
            trackingData={{
              unit_type: 'feed',
              unit_name: 'splash',
              subunit_type: 'component',
              subunit_name: 'promo_bar',
              position_in_unit: 4,
            }}
          />
        )}
        <AdSectionAwareness
          placeholderClassName={`Ad--section-awareness-wrapper ${sponsorship ? 'feed-content-area' : ''}`}
          position="body"
        />
        <main className="feed-content-area">
          <HomeFeedTabs tabs={tabs} abTestZones={abTestZones}/>
        </main>
      </HomepageWrapper>
    </>
  );
}

export async function getServerSideProps({ req, res }) {
  const { preview_timestamp, s } = req.query;
  const edition = 'en-us'; // for future use when determining editions after mvp rollout
  const staticProps = {
    namespacesRequired: ['common'],
  };

  // whether to bypass caching in redis.  Skip caching in dev on stage
  //  to match bypassing Fastly as well.  Also skip when using preview
  //  timestamp for viewing future sponsorships
  const skipCache = CLUSTER !== 'prod' || !!preview_timestamp;

  try {
    const [
      header, // Fetch Error --> 500
      feedData, // Fetch Error --> 500
      trendingTabZone,
      quizzesTabZone,
      // eslint-disable-next-line no-undef
    ] = await Promise.all([
      getSiteHeader({ edition }),
      getPageByName({
        edition,
        preview_timestamp,
        skipCache,
        tags: ['type:page', 'upstream:feed_api', 'page_type:home']
      }),
      // do not error the full page for additional zone fetching, gracefully render de-duped content
      getZoneById({
        id: 9,
        pageSize: 25,
        skipCache,
        allowFail: true,
        tags: ['type:zone', 'upstream:feed_api']
      }),
      getZoneById({
        id: 10,
        pageSize: 25,
        skipCache,
        allowFail: true,
        tags: ['type:zone', 'upstream:feed_api']
      })
    ]);

    if (!feedData || (feedData instanceof Object && !Object.keys(feedData).length)) {
      // if the fetch from feed API is a success but there's no data in the feed,
      //  throw an error, as it should always exist for the homepage
      throw new Error('No feed data found from feed API');
    }

    // Only require auth if user is coming via query param
    const authorization = (s && s === 'new_hp') ? 'bmV3OmhvbWVwYWdl' : null;
    resolveAuthorization({ req, res }, authorization);

    /**
     * @todo
     * Hopefully very short term solutions for the following:
     * - WEB-4216: allowing duplicates in trending and quizzes tabs (except duplicates from the splash)
     * - WEB-4274: Weaving the same additional trending items into multiple zones, trending splash
     *   and trending tab
     */
    const updatedZones = feedData.zones;
    const splashTrending = updatedZones.find((zone) => zone.name === 'splash_trending');
    const annexTrending = updatedZones.find((zone) => zone.name === 'trending_annex');
    updatedZones.forEach((zone, index) => {
      if (zone.name === 'splash_trending') {
        if (annexTrending) {
          // append the additional trending items to the end
          updatedZones[index].items.push(...annexTrending.items);
        }
      }

      if (zone.name === 'tab_trending') {
        if (trendingTabZone?.name === 'tab_trending') {
          const items = trendingTabZone.items.filter(
            (item) => (
              !splashTrending?.items?.some(({ content }) => content?.post?.id === item?.content?.post?.id) &&
              !annexTrending?.items.some(({ content }) => content?.post?.id === item?.content?.post?.id)
            )
          );
          updatedZones[index].items = items.slice(0, 20);
        }
      }

      if (zone.name === 'tab_quiz' && quizzesTabZone?.name === 'tab_quiz') {
        const items = quizzesTabZone.items.filter(
          (item) => !splashTrending?.items?.some(({ content }) => content?.post?.id === item?.content?.post?.id)
        );
        updatedZones[index].items = items.slice(0, 20);
      }
    });

    const zones = normalizeZones(updatedZones, {
      funModuleOverride: req.query.funModule || null,
      originTag: '',
    });
    const sponsorship = normalizeSponsorship(feedData?.sponsorships);

    const customKeys = getFeedPageSurrogateKeys({ feedPage: feedData, cluster: CLUSTER });

    setResponseHeaders({
      req,
      res,
      customKeys,
      /**
       * A max-age is defined only if served on prod and there is no basic auth required. Otherwise
       * max-age will be `0`.
       * @todo make longer once full event driven purging is set up
       */
      maxAge: CLUSTER === 'prod' && !feedData.authorization ? MAX_AGE_SHORT : 0,
      edition: 'en-us',
    });

    return {
      props: {
        ...staticProps,
        debug: { funModule: req.query.funModule || null }, // @todo remove
        header,
        metadata: feedData.metadata,
        pageConfig: getConfig({ page: 'default', edition, path: req.path }),
        sponsorship,
        tracking: {
          unit_type: 'feed',
          unit_name: 'main',
          context_page_type: 'feed',
          context_page_id: 1,
          destination: 'buzzfeed',
          page_edition: 'en-us',
        },
        zones,
        previewTimestamp: preview_timestamp ? preview_timestamp * 1000 : null,
      },
    };
  } catch (error) {
    // Handle error (WARNING: this text is used for alert monitor, so beware changing wording)
    console.error('getServerSideProps failed to complete execution on home page');
    console.error(error);
    throw error;
  }
}

export default Page;
